'use strict';

Gri.module({
  name: 'windows-sticky',
  ieVersion: null,
  $el: $('.windows-sticky'),
  container: '.windows-sticky',
  fn: function () {

    const $this = this.$el;

    resizeController();
    $(window).on('resize', resizeController);

    function resizeController() {
      let windowW = $(window).width();
      if(windowW < 992) {
        let headerH = $('.header-alpha').height();
        let elOffset = $this.offset().top - headerH;

        $(window).on('scroll', function() {
          if($(window).scrollTop() >= elOffset) {
            $this.addClass('active');
          } else {
            $this.removeClass('active');
          }
        });
      }
    }

  }
});
